import 'xp-lib-brand/index'; // import xp-lib-brand js first

import 'lazysizes/plugins/respimg/ls.respimg.js';
import 'lazysizes';
import './search-show-more';
import stickybits from 'stickybits';
import Hiraku from 'hiraku';

const leftMenu = new Hiraku('.offcanvas-left', {
	btn: '#offcanvas-btn-left',
	direction: 'left',
	width: '100%'
});

const rightMenu = new Hiraku('.offcanvas-right', {
	btn: '#offcanvas-btn-right',
	direction: 'right',
	width: '100%'
});

leftMenu.on('open', () => {
	document.getElementById('page-header__search-input').focus();
});

document
	.getElementById('offcanvas-btn-right-close')
	.addEventListener('click', () => {
		rightMenu.close();
	});

document
	.getElementById('offcanvas-btn-left-close')
	.addEventListener('click', () => {
		leftMenu.close();
	});

stickybits('.gryr-page-header');
